import React from 'react';
import Tag from '../Assets/Images/tag.png';
import Tag2 from '../Assets/Images/tag2.png';
import { FaPython, FaReact, FaFigma } from 'react-icons/fa';
import { SiStreamlit, SiMongodb, SiFlask } from "react-icons/si";
import { IoLogoFirebase } from "react-icons/io5";
import { RiFlutterFill } from "react-icons/ri";
import { TbBrandVscode } from "react-icons/tb";


function Home() {

  const handleSayHello = () => {
    window.location.href = "mailto:abimbolaikus@gmail.com";
  };

  return (
    <div id='bio-section' className='bg-primary h-auto relative pb-20'>
      <img src={Tag} alt='tag' className='absolute top-7 md:left-32 w-8 left-10 md:w-10' />
      <img src={Tag2} alt='tag' className='absolute bottom-7 md:left-32 w-8 left-10 md:w-10' />
      <div className='absolute top-5 bottom-5 left-24 md:left-52 bg-secondary w-s mx-auto'></div>
      <div className='text-justify pl-28 pr-10 md:pr-32 md:pl-64 pt-10'>
        <h3 className='text-secondary text-lg md:text-2xl font-thin'>
          SHORT BIO
        </h3>
        <p className='font-link text-sm md:text-xl pt-5'>
          Beauty is a multi-disciplinary professional with
          expertise in design, frontend development, and
          machine learning engineering. With a keen interest
          in quantum mechanics, She is always looking for
          ways to incorporate this knowledge into their work.
          Beauty is passionate about collaborating on projects
          that are simple, complicated, or normal, as long as
          they have a positive impact on people. She is
          committed to creating innovative solutions that
          push the boundaries of what is possible, while
          maintaining a focus on simplicity and user experience.
        </p>
        <div className='flex mt-3 mb-4'>
          <FaPython className='text-secondary text-2xl md:text-4xl' />
          <FaReact className='text-secondary text-2xl md:text-4xl ml-5' />
          <SiStreamlit className='text-secondary text-2xl md:text-4xl ml-5' />
          <SiMongodb className='text-secondary text-2xl md:text-4xl ml-5' />
          <IoLogoFirebase className='text-secondary text-2xl md:text-4xl ml-5' />
          <RiFlutterFill className='text-secondary text-2xl md:text-4xl ml-5' />
          <FaFigma className='text-secondary text-2xl md:text-4xl ml-5' />
          <SiFlask className='text-secondary text-2xl md:text-4xl ml-5' />
          <TbBrandVscode className='text-secondary text-2xl md:text-4xl ml-5' />
        </div>
        <p className='font-thin text-end text-xs md:text-sm'>V1-2024</p>
        <p className='font-thin cursor-pointer' onClick={handleSayHello}>
          Say Hello👋🏿
        </p>

      </div>
    </div>

  );
}

export default Home;
