import React from 'react';
import Angle from '../Assets/Images/Angle.png';
import Navbar from '../Components/Navbar';
import Blog from './Blog';
import Home from './Home';
import Projects from './Projects';
import Footer from '../Components/Footer';




function Hero() {
    return (
        <div>
            <Navbar />
            <div className='bg-small-screen md:bg-main bg-no-repeat bg-cover h-screen overflow-hidden flex justify-center items-center md:justify-end md:items-end relative'>
                <div>
                    <img src={Angle} alt='angle' className='absolute top-0 left-0' />
                    <img src={Angle} alt='angle' className='absolute rotate-180 bottom-0 right-0 md:hidden' />
                </div>
                
                <div className='text-start'>
                    <div className='p-10'>
                        <h1 className='text-primary font-link md:font-normal text-6xl md:text-9xl/none'>
                            Beauty <br />Ikudehinbu
                        </h1>
                    </div>
                </div>
                <div className='absolute bottom-0 left-3'>
                    <p className='text-primary text-xxs md:text-xs font-link -rotate-90 transform origin-left'>
                    The present is theirs; the future, for which I really worked, is mine.
                    </p>
                </div>
                <div className='absolute top-48 md:top-80 right-3'>
                    <p className='text-primary text-xxs md:text-xs font-link rotate-90 transform origin-right'>
                    The present is theirs; the future, for which I really worked, is mine.
                    </p>
                </div>
            </div>
            <Home />
            <Projects />
            <Blog />
            <Footer />
        </div>

    );
}

export default Hero;
