import {useState} from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

function Navbar() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (section) => {
        scroll.scrollTo(section, {
            duration: 800,
            smooth: 'easeInOutQuad',
        });
        setMenuOpen(false);
    };

    return (
        <div classNamecode ='relative'>
            <div
                className={`w-50 fixed cursor-pointer rounded-full bg-primary/80 backdrop-blur-sm top-5 right-10 z-40 transition-all ${isMenuOpen ? 'transform rotate-180' : 'transform rotate-0'}`}
                onClick={toggleMenu}
            >
                <div className='flex justify-center items-center h-full p-4 cursor-pointer text-xl'>
                    <ion-icon name={isMenuOpen ? 'close' : 'grid'} className='text-secondary text-center'></ion-icon>
                </div>
            </div>
            {isMenuOpen && (
                <div className='fixed z-30 right-0 bg-primary/80 backdrop-blur-sm w-1/2 h-screen text-secondary font-link text-md md:text-lg justify-center text-center tracking-wider transition-all'>
                    <ul className={`mt-36 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-500 ease-in`}>
                        <li className='mb-5 md:mb-7 hover:text-gray-700 duration-500 hover:translate-y-3 cursor-pointer'>
                        <Link
                            to='bio-section'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => isMenuOpen && setMenuOpen(false)}
                        >
                            Bio
                        </Link>
                        </li>
                        <li className='mb-5 md:mb-7 hover:text-gray-700 duration-500 hover:translate-y-3 cursor-pointer'>
                        <Link
                            to='projects-section'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => isMenuOpen && setMenuOpen(false)}                           
                        >
                            Projects
                        </Link>
                        </li>
                        <li className='mb-5 md:mb-7 hover:text-gray-700 duration-500 hover:translate-y-3 cursor-pointer'>
                        <Link
                            to='blog-section'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => isMenuOpen && setMenuOpen(false)}
                        >
                            Blog
                        </Link>
                        </li>
                        <li className='mb-5 md:mb-7 hover:text-gray-700 duration-500 hover:translate-y-3 cursor-pointer'>
                        <Link
                            to='contact-section'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={() => isMenuOpen && setMenuOpen(false)}
                        >
                            Contact Me
                        </Link>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}


export default Navbar;