import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore'
import tag from '../Assets/Images/tag4.png'
import { useNavigate } from 'react-router-dom'

function CrudBlog() {
    const [datas, setDatas] = useState([]);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const unsub = onSnapshot(collection(db, 'blog'), (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            setDatas(list);
            setLoading(false);
        },
            (error) => {
                console.log(error);
                setLoading(false);
            });

        return () => {
            unsub();
        };
    }, []);

 

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                setOpen(false);
                await deleteDoc(doc(db, 'blog', id));
                setDatas(datas.filter((data) => data.id !== id));
            }
            catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <div id='blog-section' className='bg-primary h-auto p-10 relative'>
            <div className="border-secondary border-b ml-5 "></div>
            <img src={tag} alt='tag' className='absolute top-6 md:top-5 left-10 w-8 md:w-10' />
            <div className='md:flex md:justify-betweeen w-full space-x-8 flex-wrap'>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    datas.map((data) => (
                        <div key={data.id} className='bg-secondary/20 backdrop-blur-md p-5 border text-secondary font-link mt-12 md:w-96 w-full h-auto rounded border-primary/10 '>
                            <h1 className='text-sm md:text-md mb-2'>{data.number}</h1>
                            <img src={data.img} alt='blog' className='rounded' />
                            <h2 className='text-2xl md:text-3xl mb-2'>{data.title}</h2>
                            <p className='text-xs md:text-sm'>{data.description}</p>
                            <div className='flex justify-between mt-5'>
                                <button className='bg-primary h-full text-secondary p-3 rounded font-link' onClick={() => navigate(`/updateblog/${data.id}`)}>Update</button>
                                <button className='bg-red-900 h-full text-white p-3 rounded font-link' onClick={() => handleDelete(data.id)}>Delete</button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default CrudBlog;