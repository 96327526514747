import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Footer() {
    return (
        <div id="contact-section" className='bg-small-screen md:bg-main text-primary p-5 md:p-10 flex justify-between'>
            {/* Link the "Made with 💝 by Beauty" text to the "/login" page */}
            <Link to="/login" className='text-xs font-link md:text-sm'>
                Made with 💝 by Beauty
            </Link>
            <div className='flex space-x-2 md:space-x-4'>
                <a href='https://www.facebook.com/ikudehinbu.josephine/' target='_blank' rel='noopener noreferrer'>
                    <FaFacebookF className='text-lg md:text-xl cursor-pointer transform hover:scale-125 transition-transform ease-in-out' />
                </a>
                <a href='https://twitter.com/Ikudehinbu_' target='_blank' rel='noopener noreferrer'>
                    <FaTwitter className='text-lg md:text-xl cursor-pointer transform hover:scale-125 transition-transform ease-in-out' />
                </a>
                <a href='https://www.instagram.com/mzbhewtee?igsh=OHdnMzJhd241YWxu&utm_source=qr' target='_blank' rel='noopener noreferrer'>
                    <FaInstagram className='text-lg md:text-xl cursor-pointer transform hover:scale-125 transition-transform ease-in-out' />
                </a>
                <a href='https://www.linkedin.com/in/beauty-ikudehinbu/' target='_blank' rel='noopener noreferrer'>
                    <FaLinkedinIn className='text-lg md:text-xl cursor-pointer transform hover:scale-125 transition-transform ease-in-out' />
                </a>
                <a href='https://www.github.com/mzbhewtee' target='_blank' rel='noopener noreferrer'>
                    <FaGithub className='text-lg md:text-xl cursor-pointer transform hover:scale-125 transition-transform ease-in-out' />
                </a>

            </div>
        </div>
    )
}

export default Footer