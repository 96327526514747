import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Hero from './Pages/Hero';
import Admin from './Pages/Admin';
import Login from './Pages/login'
import AddProject from './Components/AddProject';
import AddBlog from './Components/AddBlog';
import BlogPage from './Pages/BlogPage';


function App() {
  return (
    <BrowserRouter>
      <div className='overflow-hidden'>
        <Routes>
          <Route path='/' element={<Hero />} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/addproject' element={<AddProject />} />
          <Route path='/updateproject/:id' element={<AddProject />} />
          <Route path='/updateblog/:id' element={<AddBlog />} />
          <Route path='/addblog' element={<AddBlog />} />
          <Route path='/blogpage/:id' element={<BlogPage />} />
        </Routes>
       
      </div>
    </BrowserRouter>
  );
}

export default App;
