import React from 'react';

const Button = (props) => {
    return (
        <div className='items-center justify-center'>
            <button className='bg-secondary text-primary pl-10 pr-10 pt-3 pb-3 mt-10 rounded font-link'>{props.children}</button>
        </div>
    );
};

export default Button;
