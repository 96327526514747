import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Components/Button';
import CrudProject from '../Components/CrudProject';
import CrudBlog from '../Components/CrudBlog';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const Admin = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      console.log('Logging out...');
      await signOut(auth);
      console.log('Logout successful');
      navigate('/login'); // Redirect to the login page after successful logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className='h-auto relative justify-center items-center bg-secondary pb-10'>
      {auth.currentUser ? (
        <div>
          <div className='flex space-x-5 justify-end items-center bg-primary shadow-lg w-full h-auto mb-10 absolute top-0 p-5'>
            <div onClick={() => navigate('/addproject')}>
              <Button>Add Project</Button>
            </div>
            <div onClick={() => navigate('/addblog')}>
              <Button>Add Blog</Button>
            </div>
            <div onClick={handleLogout}>
              <Button>Logout</Button>
            </div>
          </div>
          <div className='pt-36'>
            <p>Welcome, {auth.currentUser.email}!</p>
            <CrudProject />
            <CrudBlog />
          </div>
        </div>
      ) : (
        <div className='pt-36 text-primary font-link text-center'>
          <p>Please log in to access the admin page.</p>
        </div>
      )}
    </div>
  );
};

export default Admin;
