import React, { useEffect, useState } from 'react';
import Button from './Button';
import { storage, db } from '../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, getDoc, doc, collection, serverTimestamp, updateDoc } from 'firebase/firestore';


const initialState = {
    number: '',
    title: '',
    description: '',
    content: '',
};

const AddBlog = () => {
    const [data, setData] = useState(initialState);
    const { number, title, description, content } = data;
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(null);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        id && getSingleData();
    }, [id]);


    const getSingleData = async () => {
        const docRef = doc(db, 'blog', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const retrievedData = { ...docSnap.data() };

            if (retrievedData.content) {
                retrievedData.content = retrievedData.content.replace(/\|n\|/g, '\n');
                setData(retrievedData);
                console.log('Document data:', docSnap.data());
            }


        } else {
            console.log('No such document!');
        }
    };

    useEffect(() => {
        const uploadFile = () => {
            const name = new Date().getTime() + file.name;
            const storageRef = ref(storage, file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed", (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);

                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                    default:
                        break;
                }
            }, (error) => {
                console.log(error);
            },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setData((prev) => ({ ...prev, img: downloadURL }));
                        console.log("File available at", downloadURL);
                    });
                }
            );
        }
        file && uploadFile();
    }, [file]);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
        setErrors({
            ...errors,
            [e.target.name]: null,
        });
        console.log(data);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const validate = () => {
        let newErrors = {};
        if (!number) {
            newErrors.number = 'Number is required';
        }
        if (!title) {
            newErrors.title = 'Title is required';
        }
        if (!description) {
            newErrors.description = 'Description is required';
        }
        if (!content) {
            newErrors.content = 'Content is required';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }
        setIsSubmit(true);

        // Replace newline characters before storing in Firestore
        const dataToStore = {
            ...data,
            content: data.content.replace(/\n/g, '|n|'),
        };

        if (!id) {
            try {
                await addDoc(collection(db, 'blog'), {
                    ...dataToStore,
                    timestamp: serverTimestamp(),
                    img: dataToStore.img,
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                await updateDoc(doc(db, 'blog', id), {
                    ...dataToStore,
                    timestamp: serverTimestamp(),
                    img: dataToStore.img,
                });
            } catch (error) {
                console.log(error);
            }
        }

        navigate('/admin');
    };

    return (
        <div className='flex justify-center items-center h-screen'>
            <div className='bg-primary/20 backdrop-blur-md p-5 border text-secondary font-link mt-12 md:w-1/3 w-full h-auto rounded border-primary/10 '>
                <h1 className='text-2xl md:text-3xl mb-2'>Add Blog</h1>
                <form onSubmit={handleSubmit} className='px-8 pb-8 mb-4 w-96'>
                    <div className='mb-4'>
                        <label className='block text-secondary text-sm font-bold mb-2' htmlFor='number'>
                            Number
                        </label>
                        <input
                            type='number'
                            name='number'
                            value={number}
                            onChange={handleChange}
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline'
                        />
                        {errors.number && <p className='text-red-500 text-xs italic'>{errors.number}</p>}
                    </div>
                    <div className='mb-4'>
                        <label className='block text-secondary text-sm font-bold mb-2' htmlFor='title'>
                            Title
                        </label>
                        <input
                            type='text'
                            name='title'
                            value={title}
                            onChange={handleChange}
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline'
                        />
                        {errors.title && <p className='text-red-500 text-xs italic'>{errors.title}</p>}
                    </div>
                    <div className='mb-4'>
                        <label className='block text-secondary text-sm font-bold mb-2' htmlFor='Description'>
                            Description
                        </label>
                        <textarea
                            type='text'
                            name='description'
                            value={description}
                            onChange={handleChange}
                            aria-multiline='true'
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline'
                        />
                        {errors.description && <p className='text-red-500 text-xs italic'>{errors.description}</p>}
                    </div>
                    <div className='mb-4'>
                        <label className='block text-secondary text-sm font-bold mb-2' htmlFor='content'>
                            Content
                        </label>
                        <textarea
                            type='text'
                            name='content'
                            value={content}
                            onChange={handleChange}
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline'
                        />
                        {errors.content && <p className='text-red-500 text-xs italic'>{errors.content}</p>}
                    </div>
                    <div className='mb-4'>
                        <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='file'>
                            File
                        </label>
                        <input
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            id='file'
                            type='file'
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className='flex items-center justify-center'>
                        {progress !== null && progress < 100 ? (
                            <p className="text-gray-500 italic">Uploading... {Math.round(progress)}%</p>
                        ) : (
                            <Button type='submit' disabled={progress !== null && progress < 100}>Submit</Button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddBlog;