import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    addDoc,
    query,
    where,
} from 'firebase/firestore';
import tag3 from '../Assets/Images/advert.png';
import { useParams } from 'react-router-dom';

function BlogPage() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const blogDocRef = doc(db, 'blog', id);
                const blogDocSnapshot = await getDoc(blogDocRef);

                if (blogDocSnapshot.exists()) {
                    const retrievedData = { id: blogDocSnapshot.id, ...blogDocSnapshot.data() };

                    console.log('Retrieved Data from Firestore:', retrievedData);

                    // Replace |n| with actual newline characters
                    if (retrievedData.content) {
                        console.log('Content before replacement:', retrievedData.content);
                        retrievedData.content = retrievedData.content.split('|n|').join('\n');
                        console.log('Content after replacement:', retrievedData.content);
                    }

                    setData(retrievedData);
                } else {
                    console.log('Blog post not found');
                }
            } catch (error) {
                console.error('Error fetching blog post:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchComments = async () => {
            try {
                const commentsCollectionRef = collection(db, 'comments');
                const querySnapshot = await getDocs(
                    query(commentsCollectionRef, where('blogPostId', '==', id))
                );

                const commentsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setComments(commentsData);
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchData();
        fetchComments();
    }, [id]);

    const handleSubmitComment = async (e) => {
        e.preventDefault();

        try {
            const commentsCollectionRef = collection(db, 'comments');
            const newCommentData = {
                blogPostId: id,
                author: 'Anonymous', // replace with the actual user or authentication information
                date: new Date().toLocaleDateString(),
                text: newComment,
            };

            await addDoc(commentsCollectionRef, newCommentData);

            // Clear the input field after submitting the comment
            setNewComment('');

            // Fetch the updated comments
            // eslint-disable-next-line
            fetchComments();
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    return (
        <div className='bg-secondary h-auto pb-10'>
            {loading ? (
                <p>Loading...</p>
            ) : data ? (
                <>
                    <div className='md:flex md:justify-between w-full flex-wrap text-primary'>
                        <img
                            src={data.img}
                            alt='project-1'
                            className='rounded w-full h-48 pt-10 object-cover mb-2'
                        />
                        <div className='p-8 font-link'>
                            <h2 className='text-3xl md:text-4xl mb-4 text-center'>{data.title}</h2>
                            <p className='text-sm md:text-md text-justify' style={{ whiteSpace: 'pre-wrap' }}>
                                {data.content}
                            </p>
                        </div>
                    </div>
                    <div className='border-primary border-b mr-5 mt-10'></div>
                    <div className='md:flex md:justify-between'>
                        <div className='pl-8 pr-8 md:w-2/3'>
                            <h3 className='text-xl text-primary md:text-2xl mb-4 font-link mt-10'>Comments</h3>
                            <form className='flex flex-col' onSubmit={handleSubmitComment}>
                                <textarea
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    className='border border-primary/50 rounded p-2 mb-2'
                                    placeholder='Comment here...'
                                    style={{ whiteSpace: 'pre-wrap' }}
                                ></textarea>
                                <p className='text-primary text-xs md:text-sm mb-2 italic'>Comment as Anonymous</p>
                                <button
                                    type='submit'
                                    className='text-primary border border-primary font-link rounded p-2 w-24 self-end'
                                >
                                    Submit
                                </button>
                            </form>
                            <div className='flex flex-col mt-10'>
                                {comments.map((comment) => (
                                    <><div key={comment.id} className='flex justify-between text-primary'>
                                        <h3 className='text-md md:text-xl font-link'>{comment.author}</h3>
                                        <p className='text-xs md:text-sm font-thin'>{comment.date}</p>
                                    </div><p className='text-xs md:text-sm font-thin text-primary'>{comment.text}</p>
                                        <div className='border-primary border-b mr-5 mt-3 mb-5'></div>

                                    </>
                                ))}

                            </div>
                        </div>
                        <div className='md:block md:w-1/3 p-8'>
                            <img
                                src={tag3}
                                alt='advert'
                                className='rounded w-full h-48 object-cover mb-2'
                            />
                        </div>
                    </div>
                </>
            ) : (
                <p className='text-primary'>Blog post not found</p>
            )}
        </div>
    );
}

export default BlogPage;
