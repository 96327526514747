import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { db, storage } from '../firebase';
import tag3 from '../Assets/Images/tag3.png';
import { useNavigate } from 'react-router-dom';

function CrudPortfolio() {
    const [datas, setDatas] = useState([]);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        setLoading(true);
        const unsub = onSnapshot(collection(db, 'portfolio'), (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            setDatas(list);
            setLoading(false);
        },
            (error) => {
                console.log(error);
                setLoading(false);
            });

        return () => {
            unsub();
        };
    }, []);

    const handleModal = (data) => {
        setOpen(true);
        setData(data);
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                setOpen(false);
                await deleteDoc(doc(db, 'portfolio', id));
                setDatas(datas.filter((data) => data.id !== id));
            }
            catch (err) {
                console.log(err);
            }
        }
        
    };

    return (
        <div id='projects-section' className='bg-secondary h-auto p-10 relative'>
            <div className="border-primary border-b mr-5 "></div>
            <img src={tag3} alt='tag' className='absolute top-6 md:top-5 right-10 w-8 md:w-10' />
            <div className='md:flex md:justify-between w-full space-x-8 flex-wrap'>

                {loading ? (
                    <p>Loading...</p>
                ) : (
                    datas.map((data) => (
                        <div
                            key={data.id}
                            className='relative flex mt-12 md:w-96 w-full h-96 rounded border border-primary/50 '
                        >
                            <div>
                                <img
                                    src={data.img}
                                    alt={`project-${data.id}`}
                                    className='rounded'
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                                <a href={data.link}>
                                    <div className='absolute bottom-20 left-5 right-5 font-link backdrop-blur-md border border-primary/50 p-3 bg-primary/40 rounded '>
                                        <div className='flex justify-between'>
                                            <h1 className='text-secondary text-md md:text-xl'>{data.title}</h1>
                                            <h1 className='text-secondary text-md md:text-xl'>{data.description}</h1>
                                        </div>

                                    </div>

                                </a>
                            </div>
                            <div className='flex justify-between absolute top-72 w-full p-5'>
                                <button className='bg-primary h-full text-secondary p-3 rounded font-link' onClick={() => navigate(`/updateproject/${data.id}`)}>Update</button>
                                <button className='bg-red-900 h-full text-white p-3 rounded font-link' onClick={() => handleDelete(data.id)}>Delete</button>
                            </div>

                        </div>



                    ))
                )}

            </div>


        </div>
    );
}

export default CrudPortfolio;
