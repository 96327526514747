import React, {useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import tag from '../Assets/Images/tag4.png';
import { useNavigate } from 'react-router-dom';
import { IoArrowForwardCircleOutline } from "react-icons/io5";



function Blog() {
  const [datas, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  
  useEffect(() => {
    setLoading(true);
    const unsub = onSnapshot(collection(db, 'blog'), (snapshot) => {
      let list = [];
      snapshot.docs.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      // Sort the list by date in ascending order (oldest to newest)
      list.sort((a, b) => a.number - b.number);
      setData(list);
      setLoading(false);
    },
      (error) => {
        console.log(error);
        setLoading(false);
      });

    return () => {
      unsub();
    };
  }, []);


  return (
    <div id='blog-section' className='bg-primary h-auto p-10 relative'>
      <h1 className='text-secondary text-md font-thin md:text-xl '>BLOG</h1>
      <div className="border-secondary border-b mr-5 grid md:grid-cols-3 "></div>
      <img src={tag} alt='tag' className='absolute top-12 right-10 w-8 md:w-10' />
      <div className='grid md:grid-cols-3 md:justify-between w-full flex-wrap'>
        {loading ? (
          <p>Loading...</p>
        ) : (
          datas.map((data) => (
            <div
              key={data.id}
              className='bg-secondary p-5 border text-primary font-link mt-12 md:w-96 w-full h-auto rounded border-primary/10 mb-4 md:mb-0'
            >
              <h1 className='text-md md:text-lg mb-2'>{data.number}</h1>
              <img
                src={data.img}
                alt={`project-${data.id}`}
                className='rounded w-full h-60 object-cover mb-2'
              />
              <div className='flex justify-between'>
                <div className=''>
                  <h2 className='text-lg md:text-xl mb-1'>{data.title}</h2>
                  <p className='text-xs md:text-xs'>{data.description}</p>
                </div>
                <div className='flex items-center justify-end'>
                  < IoArrowForwardCircleOutline className='text-primary text-3xl cursor-pointer transform hover:scale-125 transition-transform ease-in-out'
                    onClick={() => navigate(`/blogpage/${data.id}`)}
                  />

                </div>
              </div>

            </div>

          ))

        )}
      </div>
    </div>
  );
}

export default Blog;
