import React, { useEffect, useState } from 'react';
import Button from './Button';
import { storage, db } from '../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { uploadBytes, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, getDoc, doc, collection, serverTimestamp, updateDoc } from 'firebase/firestore';

const initialState = {
  title: '',
  description: '',
  link: '',
};

const AddProject = () => {
  const [data, setData] = useState(initialState);
  const { title, description, link } = data;
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSingleData();
  }, [id]);

  const getSingleData = async () => {
    const docRef = doc(db, 'portfolio', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData({ ...docSnap.data() });
    } else {
      console.log('No such document!');
    }
  };

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);

        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      }, (error) => {
        console.log(error);
      },
       () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setData((prev) => ({ ...prev, img: downloadURL }));
          console.log("File available at", downloadURL);
        });
      }
      );
    }
    file && uploadFile();
  }, [file]);

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: null,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validate = () => {
    let newErrors = {};
    if (!title) {
      newErrors.title = 'Title is required';
    }
    if (!description) {
      newErrors.description = 'Description is required';
    }
    if (!link) {
      newErrors.link = 'Link is required';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
    setIsSubmit(true);
    if(!id){
      try {
        await addDoc(collection(db, 'portfolio'), {
          ...data,
          timestamp: serverTimestamp(),
          img: data.img,
        });
      } catch (error) {
        console.log(error);
      }
    }
    else{
      try {
        await updateDoc(doc(db, 'portfolio', id), {
          ...data,
          timestamp: serverTimestamp(),
          img: data.img,
        });
      } catch (error) {
        console.log(error);
      }
    }
    
      navigate('/admin');

  };

  return (
    <div className='flex flex-col items-center justify-center font-link h-screen'>
      <h1 className='pb-10 font-link'>{id ? "Update Project" : 'Add Project'}</h1>
      <form onSubmit={handleSubmit} className='px-8 pb-8 mb-4 w-96'>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='title'>
            Title
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : ''
              }`}
            id='title'
            type='text'
            name='title'
            value={title}
            onChange={handleInputChange}
            autoFocus
          />
          {errors.title && <p className='text-red-500 text-xs italic'>{errors.title}</p>}
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='description'>
            Description
          </label>
          <textarea
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.description ? 'border-red-500' : ''
              }`}
            id='description'
            type='message'
            name='description'
            value={description}
            onChange={handleInputChange}
          ></textarea>
          {errors.description && (
            <p className='text-red-500 text-xs italic'>{errors.description}</p>
          )}
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='link'>
            Link
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.link ? 'border-red-500' : ''
              }`}
            id='link'
            type='text'
            name='link'
            value={link}
            onChange={handleInputChange}
          />
          {errors.link && <p className='text-red-500 text-xs italic'>{errors.link}</p>}
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='file'>
            File
          </label>
          <input
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='file'
            type='file'
            onChange={handleFileChange}
          />
        </div>
        <div className='flex items-center justify-center'>
          {progress !== null && progress < 100 ? (
            <p className="text-gray-500 italic">Uploading... {Math.round(progress)}%</p>
          ) : (
            <Button type='submit' disabled={progress !== null && progress < 100}>Submit</Button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddProject;
