import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const signIn = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Signed in
      const user = userCredential.user;
      console.log(user);
      // Navigate to admin after successful login
      navigate('/admin');
    } catch (error) {
      const errorMessage = error.message;
      console.error(error);
      setError(errorMessage);
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='w-80 md:w-96 p-10 rounded-md bg-secondary/70 backdrop-blur-md'>
        <h1 className='text-xl md:text-3xl text-center text-primary font-link p-5'>Login</h1>
        <div className='w-auto'>
          <form onSubmit={signIn} className='flex flex-col space-y-5'>
            <input
              type='email'
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='border border-primary/50 p-3 rounded bg-primary/10 text-primary font-link'
            />
            <input
              type='password'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='border border-primary/50 p-3 rounded bg-primary/10 text-primary font-link'
            />
            <div className='flex justify-between'>
              <div className='flex items-center'>
                <input type='checkbox' className='mr-2' />
                <p className='text-primary font-link text-sm'>Remember Me</p>
              </div>
              <p className='text-primary font-link italic text-sm'>Forgot Password?</p>
            </div>
            {error && <p className='text-red-500 text-sm'>{error}</p>}
            <button type='submit' className='bg-secondary text-primary p-3 rounded font-link'>
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
