import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import tag3 from '../Assets/Images/tag3.png';

function Projects() {
    const [datas, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const unsub = onSnapshot(collection(db, 'portfolio'), (snapshot) => {
            let list = [];
            snapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            setData(list);
            setLoading(false);
        },
        (error) => {
            console.log(error);
            setLoading(false);
        });

        return () => {
            unsub();
        };
    }, []);

    return (
        <div id='projects-section' className='bg-secondary h-auto p-10 relative'>
            <h1 className='text-primary text-md font-thin md:text-xl '>PROJECTS</h1>
            <div className="border-primary border-b mr-5 md:grid-cols-3"></div>
            <img src={tag3} alt='tag' className='absolute top-12 right-10 w-8 md:w-10' />
            <div className='grid md:grid-cols-3 md:justify-between w-full flex-wrap'>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    datas.map((data) => (
                        <div
                            key={data.id}
                            className='relative flex mt-12 md:w-96 w-full h-96 rounded bg-small-screen bg-no-repeat bg-cover border border-primary/50'
                        >
                            <img
                                src={data.img}
                                alt={`project-${data.id}`}
                                className='rounded'
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            <a href={data.link} target="_blank" rel="noopener noreferrer">
                                <div className='absolute flex justify-between bottom-5 left-5 right-5 font-link backdrop-blur-md border border-primary/50 p-3 bg-primary/40 rounded '>
                                    <h1 className='text-secondary text-md md:text-xl'>{data.title}</h1>
                                    <h1 className='text-secondary text-md md:text-xl'>{data.description}</h1>
                                </div>
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default Projects;
