// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB0NgmdaNZnpqEo01PkUpfdNRUG7eoxdHs",
  authDomain: "portfolio-9f8b3.firebaseapp.com",
  projectId: "portfolio-9f8b3",
  storageBucket: "portfolio-9f8b3.appspot.com",
  messagingSenderId: "453605699874",
  appId: "1:453605699874:web:ff55145e5256bbf796357b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app); 
